.transaction-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
}

.transaction-container_filter {
  display: flex;
  padding: 1vw;
  gap: 1vw;
}

.transaction-container_filter>input,
select {
  padding: 0.7vw 1.4vw;
}

.transaction-container_stats {
  display: flex;
  gap: 1vw;
  padding: 0;
  justify-content: space-between;
}

.transaction-container_stats>div {
  display: flex;
  overflow-x: auto;
  gap: 1vw;
  padding: 1vw;
  justify-content: flex-start;
  height: max-content;
}

.transaction-container_cgange_status>h3 {
  color: white;
  margin: 0;
  font-size: 1vw;
}

.transaction-container_cgange_status-blocks {
  display: flex;
  gap: 0.5vw;
}

.transaction-container_cgange_status-blocks>input {
  padding: 0.7vw 1.4vw;
  width: 7vw;
}

.transaction-container_cgange_status {
  display: flex;
}

.transaction-container_stats>div>div,
.transaction-container_cgange_status {
  background-color: #191c1e;
  border-radius: 0.5vw;
  padding: 0.5vw;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}

.transaction-container_stats-blocks-block {
  align-items: flex-end;
}

.transaction-container_stats-blocks-block-cost {
  font-size: 0.8vw;
}

.transaction-container_stats-blocks {
  display: flex;
  gap: 0.5vw;
}

.transaction-container_stats>div>div>h3 {
  color: white;
  margin: 0;
  font-size: 1vw;
}

.transaction-container_stats-blocks>div {
  border-radius: 0.3vw;
  padding: 0.5vw;

  display: flex;
  flex-direction: column;
}

.transaction-container_transactions {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  padding: 1vw;

  align-content: flex-start;
  justify-content: flex-start;
}

.transaction-container_transactions-item {
  display: grid;
  width: 31.8vw;
  grid-template:
    "id . . . status"
    "date infoUserWallet infoUserWallet . cost"
    "idTrade . . . infoUser"
    "trade trade trade trade trade";

  grid-template-columns: auto auto auto auto 7vw;
  grid-template-rows: 2vw 3vw 1vw auto;

  height: max-content;

  background-color: #191c1e;
  border-radius: 0.5vw;
  padding: 0.5vw;
}

.transaction-container_transactions-infoUserWallet {
  grid-area: infoUserWallet;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
}
.transaction-container_transactions-wallet {
  width: 8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.transaction-container_transactions-cost {
  grid-area: cost;
  border-radius: 0.5vw;
  justify-self: right;
  padding: 0.5vw;
  color: white;
  align-content: space-around;
  background-color: rgba(65, 98, 102, 0.74);

  width: 100%;
  text-align: center;
  margin: 0.3vw 0;

  font-size: 1.2vw;
}

.transaction-container_transactions-idTrade {
  grid-area: idTrade;
  font-size: 0.7vw;
  color: #8f8f8f;
  width: fit-content;
}

.transaction-container_transactions-id {
  grid-area: id;
  font-size: 0.7vw;
  color: white;
  align-items: center;
  display: flex;
  gap: 1vw;
}

.transaction-container_transactions-id>span:first-child {
  font-size: 1.1vw;
}

.transaction-container_transactions-typePayment {
  grid-area: typePayment;
  font-size: 0.7vw;
  color: white;

}

.transaction-container_transactions-status.success,
.transaction-container_transactions-cost.success {
  background-color: rgba(65, 102, 94, 0.74);
  color: rgba(86, 174, 154, 1);

}

.transaction-container_transactions-status.create,
.transaction-container_transactions-cost.create {
  background-color: rgba(102, 98, 65, 0.74);
  color: rgb(161, 174, 86);

}

.transaction-container_transactions-status.paymenting,
.transaction-container_transactions-cost.paymenting {
  background-color: rgba(65, 98, 102, 0.74);
  color: rgb(86, 168, 174);

}

.transaction-container_transactions-status.cancel,
.transaction-container_transactions-cost.cancel {
  background-color: rgba(102, 65, 65, 0.74);
  color: rgb(174, 86, 86);

}


.transaction-container_transactions-status {
  grid-area: status;
  font-size: 0.7vw;
  padding: 0;
  text-align: center;
  border-radius: 0.5vw;
  cursor: pointer;
  align-content: space-evenly;
}

.transaction-container_transactions-date {
  grid-area: date;
  display: flex;
  flex-direction: row;
  color: #8f8f8f;
  gap: 1vw;
}

.transaction-container_transactions-date>span {
  font-size: 0.7vw;
  width: 6vw;
}

.transaction-container_transactions-infoUser {
  grid-area: infoUser;
  font-size: 0.7vw;
  color: #8f8f8f;
  align-items: flex-end;
  justify-self: self-end;
  display: flex;
  gap: 0.5vw;
  cursor: pointer;
}


.transaction-container_transactions-trade {
  grid-area: trade;
  background-color: #252525;
  border-radius: 0.5vw;
  padding: 0.5vw;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5vw;

  height: 8vw;
  overflow: auto;
}

.transaction-container_transactions-trade>div {
  padding: 0.3vw;
  background-color: #323131;
  border-radius: 0.5vw;
  position: relative;
  height: max-content;
}

.transaction-container_transactions-trade>div>img {
  width: 3vw;

}

.transaction-container_transactions-price_tm {
  font-size: 0.5vw;
  color: #d0d0d0;
  position: absolute;
  top: 0;
  left: 0;
}

.transaction-container_transactions-price {
  font-size: 0.5vw;
  color: #d0d0d0;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 600px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.transaction-container_transactions-wallet-button_paymeting:disabled{
background-color: #506884;
}